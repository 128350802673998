import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a7ec5c68 = () => interopDefault(import('../pages/apoverlags-newsletterarchiv/index.vue' /* webpackChunkName: "pages/apoverlags-newsletterarchiv/index" */))
const _5c368cb4 = () => interopDefault(import('../pages/buch-aktuell/index.vue' /* webpackChunkName: "pages/buch-aktuell/index" */))
const _59cecda8 = () => interopDefault(import('../pages/newsletter/index.vue' /* webpackChunkName: "pages/newsletter/index" */))
const _342da19b = () => interopDefault(import('../pages/registrierung/index.vue' /* webpackChunkName: "pages/registrierung/index" */))
const _7f0ef202 = () => interopDefault(import('../pages/suche.vue' /* webpackChunkName: "pages/suche" */))
const _91ad5f42 = () => interopDefault(import('../pages/produkte/fortbildung.vue' /* webpackChunkName: "pages/produkte/fortbildung" */))
const _d4b6be4a = () => interopDefault(import('../pages/produkte/medien.vue' /* webpackChunkName: "pages/produkte/medien" */))
const _16ba813c = () => interopDefault(import('../pages/produkte/software.vue' /* webpackChunkName: "pages/produkte/software" */))
const _0e0dd1d6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _a6044cb6 = () => interopDefault(import('../pages/article/_id.vue' /* webpackChunkName: "pages/article/_id" */))
const _1d5f223c = () => interopDefault(import('../pages/author/_id.vue' /* webpackChunkName: "pages/author/_id" */))
const _3ed41e82 = () => interopDefault(import('../pages/channel/_slug.vue' /* webpackChunkName: "pages/channel/_slug" */))
const _6c49ced0 = () => interopDefault(import('../pages/product-inquiry/_id.vue' /* webpackChunkName: "pages/product-inquiry/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/apoverlags-newsletterarchiv",
    component: _a7ec5c68,
    name: "apoverlags-newsletterarchiv"
  }, {
    path: "/buch-aktuell",
    component: _5c368cb4,
    name: "buch-aktuell"
  }, {
    path: "/newsletter",
    component: _59cecda8,
    name: "newsletter"
  }, {
    path: "/registrierung",
    component: _342da19b,
    name: "registrierung"
  }, {
    path: "/suche",
    component: _7f0ef202,
    name: "suche"
  }, {
    path: "/produkte/fortbildung",
    component: _91ad5f42,
    name: "produkte-fortbildung"
  }, {
    path: "/produkte/medien",
    component: _d4b6be4a,
    name: "produkte-medien"
  }, {
    path: "/produkte/software",
    component: _16ba813c,
    name: "produkte-software"
  }, {
    path: "/",
    component: _0e0dd1d6,
    name: "index"
  }, {
    path: "/article/:id?",
    component: _a6044cb6,
    name: "article-id"
  }, {
    path: "/author/:id?",
    component: _1d5f223c,
    name: "author-id"
  }, {
    path: "/channel/:slug?",
    component: _3ed41e82,
    name: "channel-slug"
  }, {
    path: "/product-inquiry/:id?",
    component: _6c49ced0,
    name: "product-inquiry-id"
  }, {
    path: "/bestellen/:id(\\d+)",
    component: _6c49ced0,
    name: "order-canonical"
  }, {
    path: "/autor/:authorName([a-z0-9A-F%-]+)-:id(\\d+)",
    component: _1d5f223c,
    name: "author-canonical"
  }, {
    path: "/:channelSlugs([a-z0-9A-F%-]+)+/:slug([a-z0-9A-F%-]+)-:id(\\d+)",
    component: _a6044cb6,
    name: "article-canonical"
  }, {
    path: "/:channelSlugs([a-z0-9A-F%-]+)*/:slug([a-z0-9A-F%-]+)",
    component: _3ed41e82,
    name: "channel-canonical"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
